import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return <>404</>
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound)
